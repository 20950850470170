@import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@font-face {
    font-family: "Roboto";
    src: url("./shared/fonts/Roboto-Regular.woff2") format("woff2"),
        url("./shared/fonts/Roboto-Regular.woff") format("woff"),
        url("./shared/fonts/Roboto-Regular.eot") format("embedded-opentype"),
        url("./shared/fonts/Roboto-Regular.ttf") format("truetype");
}

#root {
    display: flex;
    background: #F8F4EE;
}


html .rdw-image-modal {
    width: 435px;
}

body {
    background-color: #eaeaea!important;
}
a, a:hover, a:active, a:focus {
    text-decoration: none;
    color: #000;
}
form figure {
    float: left;
    margin: 0 15px 0 0;
}